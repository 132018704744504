import React, { useEffect, useState } from 'react';
import BASE_URL from '../../../Api/base';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import RequestOfferModal from '../../../components/RequestOfferModal';

function OfferListPub() {
    const [adminOffers, setAdminOffers] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [offerId, setOfferId] = useState();
    const loggedUesr = JSON.parse(localStorage.getItem('userData'));
    const partners_Id = loggedUesr?.partners_Id;

    const fetchOfferList = async () => {
        const response = await axios.get(BASE_URL + `offer/getltsOffer?partners_Id=${partners_Id}`);
        const resData = response.data.responseResult;
        setAdminOffers(resData);
    }
    const handleButtonClick = (offer) => {
      console.log('clickedOffer', offer.offerId)
      setOfferId(offer.offerId)
      setPopupOpen(!isPopupOpen);
    };
    useEffect(() => {
        fetchOfferList();
    },[])
    useEffect(() => {
        console.log(offerId)
    },['clickedOfferId',offerId])
  return (
    <div>
        <div className='pubDashboardOffer'>
            <h3>New Offers</h3>
            <RequestOfferModal  show={isPopupOpen}
                                            onHide={() => setPopupOpen(false) } publisherId={loggedUesr.publisherId} offerId={offerId} handleButtonClick={handleButtonClick} />
      {/* <h1>OfferList</h1> */}
      <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Access Status</th>
                    <th>ID</th>
                    <th>Name</th>
                    {/* <th>Privacy Lavel</th> */}
                    <th>Model</th>
                    <th>Platform</th>
                    <th>Countries</th>
                    <th>Payout</th>
                  </tr>
                </thead>
                <tbody>
                  {adminOffers
                    ?.map((click) => (
                      <tr key={click?.id}>
                        <td><button className='btn' type="button" onClick={()=> handleButtonClick(click)}>Request Approval</button></td>
                        <td>{click?.offerId}</td>
                        <td>
                         <Link>{click?.title}</Link>
                        </td>
                        {/* <td><span className={click?.status === "ACTIVE" ? 'statusBtn' : 'statusInactive'}>{click?.status}</span></td> */}
                        {/* <td>{click?.privacyLavel}</td> */}
                        <td>{click?.eventType}</td>
                        <td>{click?.operatingSystem}</td>
                        <td>
                        <ReactCountryFlag 
                            countryCode={click?.country_code} 
                            style={{
                            fontSize: '2em',
                            lineHeight: '2em',
                            }}
                            svg
                        />
                        </td>
                        <td>{click?.payout}</td>
                        {/* <td><button className={click?.privacyLavel === "Required Aprooval" ? 'btn btn-danger btn-sm' : 'btn btn-success btn-sm'}>{click?.privacyLavel}</button></td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
              </div>
    </div>
  )
}

export default OfferListPub